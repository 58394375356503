import styled from 'styled-components'
import React, {useEffect} from 'react'
import './sub_comp_01_headlesssoftware.css'
import './sub_comp_01_flip_btn.css'
import { Grid } from '@mui/material'

const Sub_comp_01_headlesssoftware = (props: any) => {
    const { section1, section2, section3 } = props

    const scrollToSection = (elementRef:any) => {
        console.log(elementRef.current.offsetTop)
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: "smooth",
            });
    };

    
      

    return (
    <>
    <div className='sub_01_wpb_row'>
        <div className='sub_01_row_col_wrap_12'>
            <div className='sub_01_vc_col_sm_12'>
                <div className='sub_01_vc_column_inner'>

                    <div className='sub_01_column_image_bg_wrap'>
                        <div className='sub_01_inner_wrap'>
                            <div className='cub_01_column_image_bg'>

                            </div>
                        </div>
                    </div>

                    <div className='sub_01_column_bg_overlay'>
                        <div className='sub_01_column_overlay_layer'>

                        </div>
                    </div>

                    <div className='sub_01_wpb_wrapper'>
                        <CustomGrid container spacing={0} className='sub_01_row_col_wrap_12_inner'>
                            <CustomGrid item xs={12} sm={9} className='sub_01_vc_col_9_grid'>
                                <div className='sub_01_vc_column_inner_grid'>
                                    <div className='sub_01_wpb_wrapper_grid'>

                                        <div className='sub_01_img_with_animation_wrap'>
                                            <div className='sub_01_inner_grid'>
                                                <div className='sub_01_hover_wrap_inner'>
                                                    <img 
                                                        decoding="async" 
                                                        className="sub_01_img_with_animation_img skip-lazy" 
                                                        data-delay="0" 
                                                        height="46" 
                                                        width="300" 
                                                        data-animation="none" 
                                                        src={require('assets/headlesssoftware_title_02.png').default}
                                                        // src="https://craftsburysoftware.com/wp-content/uploads/2024/07/HeadlessSoftware.com_.png" 
                                                        alt="" 
                                                        sizes="(max-width: 300px) 100vw, 300px"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className='sub_01_nectar_split_heading_grid'>
                                            <h1 className='sub_01_nectar_heading_h1'>
                                                <span className='sub_01_span_outter'>
                                                    <span className="sub_01_span_inner">
                                                        Buyer's Guide: Best Headless Software 2024
                                                    </span>
                                                </span> 
                                            </h1>
                                        </div>


                                        {/* ================================= flip btns start ================================== */}


                                        <div 
                                            onClick={() => scrollToSection(section1)}
                                            className='sub_01_nectar_3d_flip_button_grid' 
                                            style={{ width: '146px', height: '50px'}}
                                        >
                                            <div className="sub_01_cubeshape sub_01_cube" style={{ width: '146px', height: '50px'}}>
                                                <div className="sub_01_first_cube" style={{ width: '146px', height: '50px'}}>
                                                    <h1><span style={{ width: '146px'}}>What's Inside?</span></h1>
                                                </div>
                                                <div className="sub_01_second_cube" style={{ width: '146px', height: '54px'}}>
                                                    <h1><span style={{ width: '146px'}}>What's Inside?</span></h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div 
                                            onClick={() => scrollToSection(section2)}
                                            className='sub_01_nectar_3d_flip_button_grid' 
                                            style={{ width: '105px', height: '50px'}}
                                        >
                                            <div className="sub_01_cubeshape sub_01_cube" style={{ width: '105px', height: '50px'}}>
                                                <div className="sub_01_first_cube" style={{ width: '105px', height: '50px'}}>
                                                    <h1><span style={{ width: '105px'}}>Why Us?</span></h1>
                                                </div>
                                                <div className="sub_01_second_cube" style={{ width: '105px', height: '54px'}}>
                                                    <h1><span style={{ width: '105px'}}>Why Us?</span></h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div 
                                            onClick={() => scrollToSection(section3)}
                                            className='sub_01_nectar_3d_flip_button_grid' 
                                            style={{ width: '102px', height: '50px'}}
                                        >
                                            <div className="sub_01_cubeshape sub_01_cube" style={{ width: '102px', height: '50px'}}>
                                                <div className="sub_01_first_cube" style={{ width: '102px', height: '50px'}}>
                                                    <h1><span style={{ width: '102px'}}>Reviews</span></h1>
                                                </div>
                                                <div className="sub_01_second_cube" style={{ width: '102px', height: '54px'}}>
                                                    <h1><span style={{ width: '102px'}}>Reviews</span></h1>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ================================= flip btns end ================================== */}

                                        <p></p>
                                        <div className='sub_01_wpb_text_column'>
                                            <div className='sub_01_wpb_wrapper_text_column'>
                                                <p>
                                                    <span>
                                                        Compare and contrast the leading headless software solutions with a free copy of <strong>Best of ‘Headless’ Software Platforms: 2024</strong> <strong>Edition</strong>. Includes side-by-side comparisons, reviews, pricing, and recommendations for choosing the best headless software platform to increase pageviews and earnings.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className='sub_01_wpb_raw_code_grid'>
                                            <div className='sub_01_btn_live_div'>
                                                {/* <div className='sub_01_download_btn'>
                                                    Download Free Report
                                                </div> */}
                                                
                                                <div data-tf-live="01J3QWAFJXFZABQ5R47Q2SZPEP"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </CustomGrid>
                            <CustomGrid item xs={12} sm={3} className='sub_01_vc_col_3_grid'>

                            </CustomGrid>
                        </CustomGrid>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </>                                                             
    );
};


const CustomGrid = styled(Grid)`
    .sub_01_row_col_wrap_12_inner {
        margin: 0;
        padding: 0;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        float: none;
        margin-bottom: 0!important;
        z-index: 10;
        width: auto;
        box-sizing: border-box;
    }

    .sub_01_vc_col_9_grid {
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 0;
        margin-right: 0;

        padding: 0;
        float: none;
        background-position: center;
        background-size: cover;
        box-shadow: none;
        position: relative;
        display: block;
        border: 0;
        box-sizing: border-box;
    }

    .sub_01_vc_col_3_grid {
        padding: 0;
        margin: 0;
        background-position: center;
        background-size: cover;
        box-shadow: none;
        position: relative;
        display: block;
        border: 0;
        box-sizing: border-box;
    }
`

export default Sub_comp_01_headlesssoftware