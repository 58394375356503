import styled from 'styled-components'
import './sub_comp_05_02_companies.css'
import { Grid } from '@mui/material'

const Sub_comp_05_02_companies = (props: any) => {
    const { section3 } = props
    return (
    <>
        <div className='sub_05_02_container_wrapper'>
            <div className='sub_05_02_row_col_wrap_12'>
                <div className='sub_05_02_vc_col_12'>
                    <div className='sub_05_02_vc_column_inner'>

                        <div className='sub_05_02_wpb_text_column_wpb'>
                            <p 
                                style={{textAlign: 'center'}}
                            >
                                <strong>Companies compared include:</strong>
                            </p>
                        </div>

                        <div className="sub_05_02_divider_wrap" data-alignment="default">
                            <div style={{height: '10px'}} className="divider"></div>
                        </div>

                        <div className='sub_05_02_img_with_animation_wrap'>
                            <div className='sub_05_02_inner'>
                                 <div className='sub_05_02_hover_wrap'>
                                    <div className='sub_05_02_hover_wrap_inner'>
                                        <img 
                                            loading="lazy" 
                                            decoding="async" 
                                            className="sub_05_02_img_with_animation_skip_lazy" 
                                            data-delay="0" 
                                            // height="300" 
                                            // width="750" 
                                            data-animation="none" 
                                            src="https://craftsburysoftware.com/wp-content/uploads/2024/08/landscape-cms2.png" 
                                            alt="" 
                                            sizes="(max-width: 750px) 100vw, 750px"
                                        />
                                    </div>
                                 </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>                                                             
    );
};


export default Sub_comp_05_02_companies