import { useRef, useEffect } from 'react';

const Buyers_guide = (props: any) => {
    useEffect(() => {
        ;(async () => {
            window.open(`https://drive.google.com/file/d/1DJyGZGqCJ_SvJheahhQXlGbjnfoSf7EJ/view?usp=sharing`, '_self')            
          })()
    }, [])

    const goto_buyer_guide = () => {
        ;(async () => {
            window.open(`https://drive.google.com/file/d/1DJyGZGqCJ_SvJheahhQXlGbjnfoSf7EJ/view?usp=sharing`, '_blank')            
          })()
    }

    return (
        <>
            {/* <div onClick={goto_buyer_guide}>
                buyers-guide
                
            </div> */}
        </>                                                             
    );
};

export default Buyers_guide