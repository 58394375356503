import styled from 'styled-components'
import './sub_comp_03_more.css'
import { Grid } from '@mui/material'

const Sub_comp_03_more = (props: any) => {
    const { section2 } = props
    return (
    <>
        <div  ref={section2} className='sub_03_container_wrapper'>
            
                <div className='sub_03_row_bg_wrap'>
                    <div className='sub_03_inner_wrapper'>
                        <div className='sub_03_row_bg_viewport'>

                        </div>
                    </div>
                    <div className='sub_03_bg_overlay'>

                    </div>
                </div>

                <div className='sub_03_row_col_12_wrap'>
                    <div className='sub_03_container'>
                        <div className='sub_03_vc_col_sm_12'>

                            <div className="sub_03_divider-wrap" data-alignment="default">
                                <div style={{height: '20px'}} className="sub_03_divider"></div>
                            </div>

                            <div className='sub_03_wpb_text_top'>
                                <h2>
                                    <span>More value: Headless Software</span>
                                </h2>
                                
                                <p>
                                    <span style={{fontWeight: 400}}>Headless software ensures digital web properties are never down, never hacked, and lightning fast. However, the technical flotsam surrounding headless software can be mind-boggling to consider, and time-consuming to absorb. The Internet is thus unfortunately littered with poor-performing websites, glitchy user experiences, and inexplicable traffic declines.&nbsp;</span>
                                </p>

                                <p style={{paddingBottom: 0}}>
                                    <span style={{fontWeight: 400}}>Our <strong>Headless Software</strong> <strong>Buyer’s Guide</strong> cuts through all that noise to reveal the best solutions for high-traffic website portfolio growth. Take advantage of:</span>
                                </p>
                            </div>

                            <div className='sub_03_nectar_fancy_ul'>
                                <ul>
                                    <li>
                                        <i><>&#x2713;</></i>
                                        
                                        <span >Rankings of top-rated headless software platforms</span>
                                    </li>
                                    <li>
                                        <i><>&#x2713;</></i>
                                        <span >In-depth product and marketplace reviews</span>
                                    </li>
                                    <li>
                                        <i><>&#x2713;</></i>
                                        <span >Buying strategies and pricing information</span>
                                    </li>
                                    <li>
                                        <i><>&#x2713;</></i>
                                        <span >Best practices and success stories</span>
                                    </li>
                                </ul>
                            </div>

                            <div className='sub_01_wpb_raw_code_grid'>
                                <div className='sub_01_btn_live_div'>
                                    {/* <div className='sub_01_download_btn'>
                                        Download Free Report
                                    </div> */}
                                    <div data-tf-live="01J3QWAFJXFZABQ5R47Q2SZPEP"></div>

                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            
            
        </div>
    </>                                                             
    );
};


const CustomGrid = styled(Grid)`
    
`

export default Sub_comp_03_more