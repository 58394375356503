import styled from 'styled-components'
import './sub_comp_02_why.css'
import { Grid } from '@mui/material'

const Sub_comp_02_why = (props: any) => {
    const { section1 } = props
    return (
    <>
        <div className='sub_02_container_wrapper'>
            <div ref={section1} className='sub_02_whats_inside'>
                <div className='sub_02_row_col_wrap_12_col'>
                    <div className='sub_02_vc_col_sm_12'>
                        <h2 style={{textAlign: 'center'}}>
                            Why decision makers love Headless Software
                        </h2>
                        <div id="professor_prebid-root" style={{marginBottom: '24px'}}></div>
                    </div>
                </div>
            </div>

            <div className='sub_02_wpb_row_vc_row_fluid'>
                <CustomGrid container spacing={0} className='sub_02_row_col_wrap_12_span'>

                    <CustomGrid item xs={12} sm={4} className='sub_02_vc_col_4_grid'>
                        <div className='sub_02_vc_column_inner'>
                            <div className='sub_02_wpb_wrapper_grid'>

                                <div className='sub_02_wpb_row_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_vc_col_sm_12_grid'>
                                            <div className='sub_02_nectar_icon_wrap'>
                                                <div className='sub_02_nectar_icon_grid'>
                                                    <span className='sub_02_im_icon_wrap_grid'>
                                                        <span>
                                                            <svg role="presentation" version="1.1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32">
                                                                <path fill="#ff5433" d="M16 1.333c8.085 0 14.667 6.58 14.667 14.667 0 8.085-6.581 14.667-14.667 14.667-8.087 0-14.667-6.581-14.667-14.667 0-8.087 6.58-14.667 14.667-14.667zM16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16v0zM16 20c-2.205 0-4-1.795-4-4s1.795-4 4-4c2.205 0 4 1.795 4 4s-1.795 4-4 4zM16 13.333c-1.471 0-2.667 1.196-2.667 2.667 0 1.469 1.196 2.667 2.667 2.667 1.472 0 2.667-1.195 2.667-2.667 0-1.471-1.195-2.667-2.667-2.667zM8 24.779c-0.169 0-0.34-0.065-0.471-0.195-0.261-0.256-0.263-0.681-0.003-0.943l16-16.111c0.261-0.26 0.684-0.261 0.945-0.003 0.26 0.26 0.261 0.681 0.003 0.944l-16 16.111c-0.132 0.131-0.304 0.196-0.475 0.196zM24.667 12.667c-0.367 0-0.667-0.299-0.667-0.667v-4h-4c-0.367 0-0.667-0.299-0.667-0.667s0.3-0.667 0.667-0.667h4.667c0.367 0 0.667 0.299 0.667 0.667v4.667c0 0.368-0.3 0.667-0.667 0.667z"></path>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_02_wpb_title_row_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_vc_col_sm_12_title_grid'>
                                            <div className='sub_02_wpb_text_column_grid'>
                                                <h4>
                                                    <strong>1. Side-By-Side Comparisons</strong>
                                                </h4>
                                                <div id="professor_prebid-root" style={{marginBottom: '24px'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_02_wpb_row_text_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_col_sm_12_inner_grid'>
                                            <div className='sub_02_wpb_text_col_grid'>
                                                <p>
                                                    <span style={{fontWeight: 400}}>View pros and cons of each headless software platform, as well as pricing and features. We’ve reviewed each of the highest-rated headless software solutions currently available.&nbsp;</span>
                                                </p>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </CustomGrid>

                    <CustomGrid item xs={12} sm={4} className='sub_02_vc_col_4_grid'>
                        <div className='sub_02_vc_column_inner'>
                            <div className='sub_02_wpb_wrapper_grid'>

                                <div className='sub_02_wpb_row_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_vc_col_sm_12_grid'>
                                            <div className='sub_02_nectar_icon_wrap'>
                                                <div className='sub_02_nectar_icon_grid'>
                                                    <span className='sub_02_im_icon_wrap_grid'>
                                                        <span>
                                                            <svg role="presentation" version="1.1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32">
                                                                <path fill="#ff5433" d="M24.668 31.859h-17.333c-0.367 0-0.667-0.3-0.667-0.667v-23.087c0-0.177 0.071-0.347 0.195-0.472l6.251-6.251c1.653-1.656 4.331-1.656 5.984 0l6.044 6.257c0.123 0.128 0.191 0.291 0.191 0.467v23.084c0.003 0.368-0.297 0.668-0.664 0.668zM8.001 30.525h16v-22.152l-5.852-6.057c-1.139-1.139-2.94-1.143-4.091 0.008l-6.057 6.057v22.144zM16.004 25.92c-1.864 0-3.12-1.088-3.12-2.709 0-0.368 0.3-0.667 0.667-0.667s0.667 0.299 0.667 0.667c0 1.277 1.367 1.376 1.787 1.376s1.787-0.099 1.787-1.376c0-0.772-0.235-0.944-1.692-1.663l-0.775-0.383c-1.417-0.695-2.44-1.2-2.44-2.86 0-1.636 1.252-2.729 3.12-2.729 1.845 0 3.084 1.093 3.084 2.729 0 0.365-0.299 0.667-0.667 0.667-0.365 0-0.667-0.301-0.667-0.667 0-1.299-1.339-1.396-1.751-1.396-0.419 0-1.787 0.099-1.787 1.396 0 0.773 0.235 0.943 1.695 1.664l0.773 0.383c1.416 0.695 2.435 1.197 2.435 2.859 0.004 1.621-1.252 2.709-3.116 2.709zM15.92 14.991c-0.189 0-0.347-0.065-0.479-0.197-0.12-0.12-0.188-0.295-0.188-0.469 0-0.187 0.068-0.36 0.197-0.477 0.243-0.241 0.683-0.241 0.933 0 0.12 0.137 0.189 0.305 0.189 0.477 0 0.175-0.069 0.333-0.189 0.469-0.116 0.119-0.292 0.197-0.464 0.197zM15.249 14.321h1.333v1.536h-1.333v-1.536zM15.92 27.859c-0.189 0-0.372-0.068-0.495-0.2-0.104-0.12-0.185-0.293-0.185-0.467s0.081-0.349 0.197-0.469c0.213-0.241 0.695-0.251 0.947 0 0.12 0.12 0.196 0.296 0.196 0.469s-0.080 0.347-0.196 0.479c-0.131 0.12-0.292 0.188-0.464 0.188zM15.249 25.192h1.333v2.004h-1.333v-2.004zM16.215 9.733c-1.347 0-2.443-1.096-2.443-2.443 0-1.349 1.096-2.443 2.443-2.443s2.443 1.093 2.443 2.443c0 1.347-1.096 2.443-2.443 2.443zM16.215 6.181c-0.612 0-1.109 0.497-1.109 1.109s0.497 1.109 1.109 1.109 1.109-0.497 1.109-1.109-0.497-1.109-1.109-1.109z"></path>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_02_wpb_title_row_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_vc_col_sm_12_title_grid'>
                                            <div className='sub_02_wpb_text_column_grid'>
                                                <h4>
                                                    <strong>2. Pricing Transparency</strong>
                                                </h4>
                                                <div id="professor_prebid-root" style={{marginBottom: '24px'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_02_wpb_row_text_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_col_sm_12_inner_grid'>
                                            <div className='sub_02_wpb_text_col_grid'>
                                                <p>
                                                    <span style={{fontWeight: 400}}>Your top three priorities: audience growth, revenue generation, and engineering savings. We highlight them all, mapping out critical competitive differences between each headless software provider.&nbsp;</span>
                                                </p>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </CustomGrid>

                    <CustomGrid item xs={12} sm={4} className='sub_02_vc_col_4_grid'>
                        <div className='sub_02_vc_column_inner'>
                            <div className='sub_02_wpb_wrapper_grid'>

                                <div className='sub_02_wpb_row_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_vc_col_sm_12_grid'>
                                            <div className='sub_02_nectar_icon_wrap'>
                                                <div className='sub_02_nectar_icon_grid'>
                                                    <span className='sub_02_im_icon_wrap_grid'>
                                                        <span>
                                                            <svg role="presentation" version="1.1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32">
                                                                <path fill="#ff5433" d="M31.333 29.333h-30.667c-0.368 0-0.667-0.3-0.667-0.667v-25.333c0-0.367 0.299-0.667 0.667-0.667h30.667c0.367 0 0.667 0.3 0.667 0.667v25.333c0 0.367-0.3 0.667-0.667 0.667zM1.333 28h29.333v-24h-29.333v24zM30.667 9.333h-29.333c-0.368 0-0.667-0.3-0.667-0.667s0.299-0.667 0.667-0.667h29.333c0.367 0 0.667 0.3 0.667 0.667s-0.3 0.667-0.667 0.667zM12.252 25.877c-0.137 0-0.275-0.041-0.391-0.128-0.235-0.169-0.333-0.472-0.244-0.748l1.285-3.991-3.361-2.475c-0.232-0.172-0.328-0.472-0.237-0.749s0.345-0.463 0.633-0.463h4.147l1.281-3.943c0.179-0.549 1.089-0.549 1.268 0l1.28 3.943h4.147c0.288 0 0.543 0.189 0.635 0.463 0.088 0.273-0.005 0.577-0.239 0.749l-3.361 2.477 1.284 3.972c0.088 0.276-0.011 0.577-0.243 0.747-0.235 0.169-0.549 0.171-0.784 0.001l-3.356-2.427-3.356 2.443c-0.112 0.087-0.249 0.128-0.388 0.128zM11.964 18.667l2.116 1.561c0.231 0.171 0.327 0.471 0.239 0.741l-0.805 2.5 2.092-1.527c0.233-0.169 0.549-0.171 0.783-0.001l2.093 1.517-0.804-2.481c-0.087-0.273 0.009-0.576 0.239-0.745l2.117-1.565h-2.607c-0.289 0-0.544-0.187-0.633-0.461l-0.799-2.453-0.797 2.453c-0.088 0.275-0.344 0.461-0.633 0.461h-2.6zM4 6c0 0.368-0.299 0.667-0.667 0.667s-0.667-0.299-0.667-0.667c0-0.368 0.299-0.667 0.667-0.667s0.667 0.299 0.667 0.667zM6.667 6c0 0.368-0.299 0.667-0.667 0.667s-0.667-0.299-0.667-0.667c0-0.368 0.299-0.667 0.667-0.667s0.667 0.299 0.667 0.667zM9.333 6c0 0.368-0.299 0.667-0.667 0.667s-0.667-0.299-0.667-0.667c0-0.368 0.299-0.667 0.667-0.667s0.667 0.299 0.667 0.667z"></path>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_02_wpb_title_row_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_vc_col_sm_12_title_grid'>
                                            <div className='sub_02_wpb_text_column_grid'>
                                                <h4>
                                                    <strong>3. Faster Selection Process</strong>
                                                </h4>
                                                <div id="professor_prebid-root" style={{marginBottom: '24px'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub_02_wpb_row_text_grid'>
                                    <div className='sub_02_row_col_wrap_12_inner_grid'>
                                        <div className='sub_02_col_sm_12_inner_grid'>
                                            <div className='sub_02_wpb_text_col_grid'>
                                                <p>
                                                    <span style={{fontWeight: 400}}>Our headless software research cuts review and selection time by 50% or more. Shortlist and find the best headless software for your business. Create more value, sooner.&nbsp;</span>
                                                </p>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </CustomGrid>

                </CustomGrid>
            </div>
        </div>
    </>                                                             
    );
};


const CustomGrid = styled(Grid)`
    .sub_02_row_col_wrap_12_span {
        margin: 0;
        padding: 0;
        border: 0;

        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 0!important;

        float: none;
        display: flex;
        flex-wrap: wrap;

        z-index: 10;
        width: auto;
        position: relative;
    }

    .sub_02_vc_col_4_grid {
        margin: 0;
        padding: 0;
        border: 0;

        box-sizing: border-box;

        margin-left: 0;
        float: none;
        margin-right: 0;

        padding-left: 20px;
        padding-right: 20px;

        text-align: center;
        box-shadow: none;
        background-position: center;
        position: relative;
        display: block;
    }
`

export default Sub_comp_02_why