import styled from 'styled-components'
import './home.css'
import Sub_comp_01_headlesssoftware from './components/sub_comp_01/Sub_comp_01_headlesssoftware';
import Sub_comp_02_why from './components/sub_comp_02/Sub_comp_02_why';
import Sub_comp_03_more from './components/sub_comp_03/Sub_comp_03_more';
import Sub_comp_04_what from './components/sub_comp_04/Sub_comp_04_what';
import Sub_comp_05_partnership_img from './components/sub_comp_05/Sub_comp_05_partnership_img';
import Sub_comp_05_02_companies from './components/sub_comp_05_02/Sub_comp_05_02_companies';
import Sub_comp_06_submit from './components/sub_comp_06/Sub_comp_06_submit';
import Sub_comp_07_footer from './components/sub_comp_07/Sub_comp_07_footer';
import { useRef } from 'react';

const Home = (props: any) => {
    const section1 = useRef(null)
    const section2 = useRef(null)
    const section3 = useRef(null)

    return (
    <>
    <div className='top_wrapper'>
        <div className='ocm_wrapper'>
            <div className='ajax_content_wrapper'>
                <div className='container_wrapper'>
                    <Sub_comp_01_headlesssoftware 
                        section1={section1}
                        section2={section2}
                        section3={section3}
                    />
                    <Sub_comp_02_why 
                        section1={section1}
                    />
                    <Sub_comp_03_more 
                        section2={section2}
                    />
                    <Sub_comp_04_what 
                        section3={section3}
                    />
                    <Sub_comp_05_partnership_img />
                    <Sub_comp_05_02_companies />
                    <Sub_comp_06_submit />
                    <Sub_comp_07_footer />
                </div>
            </div>
        </div>
    </div>
    </>                                                             
    );
};

export default Home