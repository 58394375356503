import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Paper, IconButton, InputBase, Divider, Menu, MenuItem, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { screenSizes } from 'shared/styles/theme'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import history from 'modules/app/components/history'
// import { get_menu_data } from 'modules/api/market-api'

const ITEM_HEIGHT = 48;

const Header = (props: any) => {
  const location = useLocation();
  const [ searchValue, setSearchValue ] = useState('')
  const [ current_url, setCurrentUrl ] = useState('')
  
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [ menu_data, setMenuData ] = useState<any>([])

  useEffect(() => {
    (async () => {
      // let menu_data_result = await get_menu_data()
      // setMenuData(menu_data_result.menu_data)
    })()
      
  }, [])
  
  useEffect(() => {
    setCurrentUrl(location.pathname)
  }, [location])

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleClick = (index: number, event: React.MouseEvent<HTMLElement>, btnText: string) => {
      setAnchorEl({ [index]: event.currentTarget });
  };

  const changeProduct = (product: any) => {
      setAnchorEl(null);
 
      let product_url = product.toLowerCase().split(' ').join('-')
      history.push(`/${product_url}`)
  }

  const goStartPage = () => {
      history.push(`/`)
  } 

  return (
    <>
      {
        current_url === '/admin' ? <></>
        : 
        current_url === '/admin/product' ? <></>
        : 
        current_url === '/admin/signin' ? <></>
        : 
        current_url === '/quiz/list/all' ? <></>
        :
        current_url.startsWith('/quiz/') === true ? <></>
        :
        current_url === '/admin/quiz' ? <></>
        :
        current_url === '/admin/quiz-analyze' ? <></>
        :
        current_url === '/admin/quiz-analyze-users' ? <></>
        :
        current_url === '/admin/data' ? <></>
        :
        <>
            {/* <HeaderContainer>
              <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
                <CustomGrid item xs={4} className="grid-header-left header_logo" onClick={goStartPage} >
                  <img src={require('assets/logo_avatar3.png').default} style={{width: '52px!important'}} alt="" />
                  &nbsp;<span><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</span>
                </CustomGrid>
                <CustomGrid item xs={8} className="grid-flex-item-end grid-header-right" >
                  <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                    className="custom-paper"
                  >
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => { setSearchValue('') }}>
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'search google maps' }}
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value)
                      }}
                    />
                  </Paper>
                </CustomGrid>
              </CustomGrid>
              <Divider />
              <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
                <CustomGrid item xs={12} className="grid-header-left grid-flex-item-start">
                    {menu_data.length > 0 && menu_data.map((item:any, key:number) => (
                      <DropDownDiv key={key}>
                        <CustomButton
                            id={`demo-customized-button-${key}`}
                            variant="contained"
                            disableElevation
                            // onClick={(e) => handleClick(key, e, item.title)}
                            onClick={(e) => handleClick(key, e, item.title._id)}
                            endIcon={<ArrowDropDownIcon />}
                            sx={{ textTransform: "none" }}
                        >
                            {item.title._id}
                        </CustomButton>
                        <CustomeMenu
                            id={`long-menu-${key}`}
                            anchorEl={ anchorEl && anchorEl[key] }
                            open={ Boolean(anchorEl && anchorEl[key]) }
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 8.5,
                                    width: '22ch',
                                },
                            }}
                        >
                            {item.options.length > 0 && item.options.map((option: any, index: number) => (
                                <MenuItem key={index} onClick={() => changeProduct(option.product_name)}>
                                    {option.product_name}
                                </MenuItem>
                            ))}
                        </CustomeMenu>
                      </DropDownDiv>
                    ))}
                </CustomGrid>
              </CustomGrid>
              <Divider />
            </HeaderContainer> */}
        </>
      }
    </>
    

  )
}

const HeaderContainer = styled.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
const BoldSpan = styled.span`
  font-weight: 700;
`

const CustomButton = styled(Button)`
`
const CustomeMenu = styled(Menu)`
`
const DropDownDiv = styled.div`
    ${CustomButton} {
        font-family: GT Pressura,sans-serif;
        font-weight: 600;
        line-height: 1.33;
        font-size: 24px;
        letter-spacing: 0.03em;
        color: #000;
        background-color: transparent;

        &:hover {
            background-color: #1e947e;
            // background-color: #37a4f1;
            color: white;
        }
    }

`
const CustomGrid = styled(Grid)`
  background-color: white!important;
  padding: 8px 20px;
  margin: 0px!important;

  .header_logo{
    display: flex;
    align-items: center;
    font-size: 25px;
    cursor: pointer;
  }

  img {
    // width: 192px;
    // height: 48px;
    width: 45px;
    height: 45px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
    ${DropDownDiv} {
        margin: 0 5px;
    }
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
`

export default withRouter(Header)
