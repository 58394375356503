import styled from 'styled-components'
import './sub_comp_07_footer.css'
import { Grid } from '@mui/material'

const Sub_comp_07_footer = (props: any) => {
    return (
    <>
        <div className='sub_07_container_wrapper'>
            <div className='sub_07_whats_inside'>
               <div className='sub_07_wpb_row_vc_fluid'>
                    <div className='sub_07_row_col_wrap_12'>
                        <div className='sub_07_vc_col_sm_12'>
                            <div className='sub_07_vc_row_fluid_ft'>
                                <CustomGrid container spacing={0} className='sub_07_row_col_wrap_12'>
                            
                                    <CustomGrid item xs={12} sm={8} className='sub_07_vc_col__grid'>
                                        <div className='sub_07_wpb_wrapper'>

                                            <div className='sub_07_img_with_animation'>
                                                <div className='sub_07_inner_img_div'>
                                                    <div className='sub_07_hover_wrap'>
                                                        <div className='sub_07_hover_wrap_inner'>
                                                            <img 
                                                                decoding="async" 
                                                                className="img-with-animation skip-lazy" 
                                                                data-delay="0" 
                                                                height="93" 
                                                                width="300" 
                                                                data-animation="none" 
                                                                // src={require('assets/headlesssoftware_title.png').default}
                                                                src={require('assets/headlesssoftware_footer_02.png').default}
                                                                alt="" 
                                                                sizes="(max-width: 417px) 100vw, 417px"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='sub_07_nectar_split_heading'>
                                                <p>
                                                    {/* <span> */}
                                                        <span className="sub_07_inner" style={{transform: 'translate(0px, 0px)', opacity: 1}}>© Headless Software.com. All Rights Reserved. (415) 343-5544</span>
                                                    {/* </span>  */}
                                                </p>
                                            </div>

                                            {/* <div className='sub_07_wpb_text_column_ft'>
                                                <p>
                                                    <a href="http://twitter.com">Twitter</a> / <a href="http://facebook.com">Facebook</a> / <a href="http://linkedin.com">LinkedIn</a>
                                                </p>

                                                <div id="sub_07_professor_prebid_root"></div>
                                            </div> */}

                                        </div>
                                    </CustomGrid>

                                    <CustomGrid item xs={12} sm={4} className='sub_07_vc_col__grid'>
                                        <div className='sub_07_wpb_wrapper_02'>
                                            <p>
                                            Headless Software, Inc<br/>
                                            {/* 1110 S El Camino Real<br/>
                                            San Mateo, CA 94402<br/> */}
                                            <a href="mailto:info@headlesssoftware.com">info@headlesssoftware.com</a>
                                            </p>
                                        </div>

                                        <div id="sub_07_professor_prebid_root"></div>
                                        <div id="sub_07_professor_prebid_root"></div>
                                    </CustomGrid>

                                    {/* <CustomGrid item xs={12} sm={2} >

                                    </CustomGrid> */}
                                </CustomGrid>
                            </div>
                        </div>

                    </div>
               </div>
            </div>
        </div>
    </>                                                             
    );
};


const CustomGrid = styled(Grid)`
    .sub_07_row_col_wrap_12 {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;

        margin-left: -20px;
        margin-right: -20px;
        display: flex;
        flex-wrap: wrap;

        z-index: 10;
        margin-bottom: 0!important;

        float: none;
        width: auto;
        position: relative;

    }

    .sub_07_vc_col__grid {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;

        float: none;
        margin-right: 0;
        margin-left: 0;

        padding-left: 20px;
        padding-right: 20px;

        box-shadow: none;

        background-position: center;
        position: relative;
        display: block;
    }
`

export default Sub_comp_07_footer