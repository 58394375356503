import styled from 'styled-components'
import './sub_comp_05_partnership_img.css'
import { Grid } from '@mui/material'

const Sub_comp_05_partnership_img = (props: any) => {
    return (
    <>
        <div className='sub_05_container_wrapper'>
            <div className='sub_05_whats_inside'>
               <div className='sub_05_wpb_row_vc_fluid'>
                    <div className='sub_05_row_col_wrap_12'>
                        <div className='sub_05_vc_col_sm_12'>

                            <div className='sub_05_wpb_text_column'>
                                <p style={{textAlign: 'center'}}>In partnership with</p>
                            </div>

                            <div className="sub_05_divider-wrap" data-alignment="default">
                                <div style={{height: '20px'}} className="sub_05_divider"></div>
                            </div>

                            <div className='sub_05_img_with_animation'>
                                <div className='sub_05_inner'>
                                    <div className='sub_05_hover_wrap'>
                                        <div className='sub_05_hover_wrap_inner'>
                                            <img 
                                                loading="lazy" 
                                                decoding="async" 
                                                className="img-with-animation skip-lazy" 
                                                data-delay="0" 
                                                height="80" 
                                                width="100%" 
                                                data-animation="none"
                                                src={require('assets/headless_partners_img.png').default}
                                                alt="" 
                                                sizes="(max-width: 5029px) 100vw, 5029px"
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
               </div>
            </div>
        </div>
    </>                                                             
    );
};


const CustomGrid = styled(Grid)`
    
`

export default Sub_comp_05_partnership_img